// Generated by Framer (2a8efd8)

import { addFonts, cx, CycleVariantState, Image, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["ZiN6W1uqu"];

const serializationHash = "framer-N55D3"

const variantClassNames = {ZiN6W1uqu: "framer-v-q7mo5q"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "ZiN6W1uqu", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition}><Image {...restProps} background={{alt: "", fit: "fill", intrinsicHeight: 3648, intrinsicWidth: 5472, pixelHeight: 3648, pixelWidth: 5472, sizes: "min(406px, 100vw)", src: "https://framerusercontent.com/images/yvpeduhqMHJ0hGiweBJT51hVIY.jpg", srcSet: "https://framerusercontent.com/images/yvpeduhqMHJ0hGiweBJT51hVIY.jpg?scale-down-to=512 512w, https://framerusercontent.com/images/yvpeduhqMHJ0hGiweBJT51hVIY.jpg?scale-down-to=1024 1024w, https://framerusercontent.com/images/yvpeduhqMHJ0hGiweBJT51hVIY.jpg?scale-down-to=2048 2048w, https://framerusercontent.com/images/yvpeduhqMHJ0hGiweBJT51hVIY.jpg?scale-down-to=4096 4096w, https://framerusercontent.com/images/yvpeduhqMHJ0hGiweBJT51hVIY.jpg 5472w"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-q7mo5q", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"ZiN6W1uqu"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{borderBottomLeftRadius: 15, borderBottomRightRadius: 15, borderTopLeftRadius: 15, borderTopRightRadius: 15, ...style}}/></Transition></Variants>
</LayoutGroup>)

});

const css = [".framer-N55D3[data-border=\"true\"]::after, .framer-N55D3 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-N55D3.framer-1738oxs, .framer-N55D3 .framer-1738oxs { display: block; }", ".framer-N55D3.framer-q7mo5q { height: 465px; overflow: hidden; position: relative; width: 406px; will-change: var(--framer-will-change-override, transform); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 465
 * @framerIntrinsicWidth 406
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const Framerrsa71FoWy: React.ComponentType<Props> = withCSS(Component, css, "framer-N55D3") as typeof Component;
export default Framerrsa71FoWy;

Framerrsa71FoWy.displayName = "Gallery Image";

Framerrsa71FoWy.defaultProps = {height: 465, width: 406};

addFonts(Framerrsa71FoWy, [])